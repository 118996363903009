// import '../helpers/webpack_public_path';
import '../../sass/base.scss';
import '../../sass/components/header/base_header.scss';
import '../../sass/components/utils.scss';
import '../../sass/components/tool_tip.scss';
import '../../sass/components/popup_box.scss';
import '../../sass/components/home/search.scss';
import '../../sass/components/landing/website_features.scss';
import '../../sass/components/home/apps_banner.scss';
import '../../sass/components/home/post_ad.scss';
import '../../sass/components/home/search_locations.scss';
import '../../sass/components/home/upgrade.scss';
import '../../sass/components/home/promos.scss';
import '../../sass/components/landing/as_featured_by.scss';
import '../../sass/pages/home.scss';
import '../../sass/components/home/mobile_app_banner.scss';
import '../../sass/components/home/verified.scss';
import Cookies from 'js-cookie';
import facebookLogin from '../services/facebookLogin/index';
import Geo from '../services/geo/geo';
import updateNewestProfile from '../components/tracking/updateNewestProfile';
import flashMessage from '../apps/flash_message/helpers/get_flash_message';
import triggerRegistrationPrompt from '../components/list/registration_prompt';
import triggerRegistrationPromptMobile from '../components/list/registration_prompt_mobile_with_exp';
import { fetchData } from '../helpers/base_data_fetcher';
import '../components/live_rent_free_banner.js';
import '../components/tube_ad_banner.js';
import '../components/tracking/home_post_ad.js';
import '../components/tracking/verified_tracking.js';
import trackEvent from '../helpers/gtm/eventPush';

const backgroundImageWrapper = document.querySelector('.hero__image-wrapper');
const getMyLocation = document.querySelector('#getLocation');

const searchField = document.querySelector('#search_by_location_field');

searchField.addEventListener('focus', () => {
  backgroundImageWrapper.classList.add('hero__image-wrapper--blur');
  if (window._sr.site.mobile) {
    searchInputPosition();
  }
});
searchField.addEventListener('blur', () => {
  backgroundImageWrapper.classList.remove('hero__image-wrapper--blur');
  if (window._sr.site.mobile) {
    clearSearchInputPosition();
  }
});

getMyLocation.addEventListener('click', () => {
  const geoInstance = new Geo();
  geoInstance
    .getPostcode()
    .then((result) => {
      searchField.value = result;
    })
    .catch((error) => {
      if (flashMessage) {
        return flashMessage.showWarningMessage(error.message);
      }
      return false;
    });
});

if (window._sr.session.loggedin) {
  updateNewestProfile();
}

function searchInputPosition() {
  const searchFieldOffset =
    searchField.getBoundingClientRect().top + window.pageYOffset;
  document.body.scrollTop = 0;
  const scrollStep = searchFieldOffset / (200 / 15);
  const scrollInterval = setInterval(() => {
    if (window.scrollY < searchFieldOffset - 20) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
}

function clearSearchInputPosition() {
  document.activeElement.blur();
  const searchFieldOffset =
    searchField.getBoundingClientRect().top + window.pageYOffset;
  const scrollStep = -searchFieldOffset / (100 / 15);
  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
    } else clearInterval(scrollInterval);
  }, 15);
}

/**
 * mobile sticky apps banner
 */

/**
 * @param {element} element the close button element
 */
function closeMobileAppsBanner(element) {
  Cookies.set('apps_banner_dismissed', 1, { expires: 30 });
  element.classList.add('apps-banner--is-closing');
  setTimeout(() => {
    element.remove();
  }, 400);
}

const mobileAppsBanner = document.querySelector('#mobile-apps-banner');
if (mobileAppsBanner) {
  const mobileAppsBannerCloseButton = mobileAppsBanner.querySelector(
    '.apps-banner__close',
  );
  if (mobileAppsBannerCloseButton) {
    mobileAppsBannerCloseButton.addEventListener('click', () =>
      closeMobileAppsBanner(mobileAppsBanner),
    );
  }
}

/**
 * end mobile sticky apps banner
 */

if (window._sr.site.mobile) {
  triggerRegistrationPromptMobile();
} else {
  triggerRegistrationPrompt();
}

function initiateFacebookLoginOrRegister() {
  Promise.all([
    fetchData('page'),
    fetchData('site'),
    fetchData('affiliate'),
    fetchData('config'),
  ]).then(([page, site, affiliate, config]) => {
    facebookLogin.initialiseApp({ language: config.facebook.app_locale });
    const facebookButtons = document.querySelectorAll(
      '[data-facebook-connect]',
    );
    facebookButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        facebookLogin.login(
          {
            loginfrom_url: page.loginReturnPath,
            referred_by: affiliate.id,
          },
          site.sub_dir,
        );
      });
    });
  });
}

initiateFacebookLoginOrRegister();

/** upgrade promo tracking */
const upgradeButton = document.querySelector('.upgrade-promo .button');

if (upgradeButton) {
  upgradeButton.addEventListener('click', () => {
    trackEvent({
      category: 'upgrade',
      action: 'upgrade_now',
      label: window.location.href,
    });
  });
}
